import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const monitorData = createSlice({
    name: 'monitorData',
    initialState: {
        monitorTime: new Date().getTime(),
        salesData: null,
        radarData: null,
        monthData: null,
        stringData: null,
        leaderData: null,
        dumpingLeaders: null,
    },
    reducers: {
        setMonitorTime: (state, action) => {
            state.monitorTime = action.payload
        },
        setSalesData: (state, action) => {
            state.salesData = action.payload
        },
        setMonthData: (state, action) => {
            state.monthData = action.payload
        },
        setStringData: (state, action) => {
            state.stringData = action.payload
        },
        setLeaderData: (state, action) => {
            state.leaderData = action.payload
        },
        setRadarData: (state, action) => {
            state.radarData = action.payload
        },
        setDumpingLeaders: (state, action) => {
            state.dumpingLeaders = action.payload
        },
    }
})

export const { setSalesData, setMonthData, setStringData, setLeaderData, setRadarData, setMonitorTime, setDumpingLeaders } = monitorData.actions

export const getSalesDataMonitor = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sales/monitor`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setSalesData(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setSalesData(null))
        });
}

export const getMonthDataMonitor = () => (dispatch, getState) => {
    let token = getState().auth.token

    let date = new Date()
    let dt = date.toISOString().split('T')[0]
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/month?date=${dt}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setMonthData(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setMonthData(null))
        });
}

export const getStringDataMonitor = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/string`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setStringData(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setStringData(null))
        });
}

export const getLeaderDataMonitor = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/leader`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setLeaderData(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setLeaderData(null))
        });
}

export const getRadarDataMonitor = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/radar`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setRadarData(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setRadarData(null))
        });
}

export const getDumpingLeaders = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/dumping`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setDumpingLeaders(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingLeaders(null))
        });
}

export default monitorData.reducer
import TopBlock from "../../../../components/TopBlock"

const SellersAppPage = () => {
    return (
        <>
            <TopBlock title="Продавцы" back="Главная" backLink={"/app"} />
        </>
    )
}

export default SellersAppPage
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Dleader = () => {
    const [list, setList] = useState([])

    const dumpingLeaders = useSelector((state) => state.monitorData.dumpingLeaders)

    useEffect(() => {
        if (dumpingLeaders) {
            let tmp = [...dumpingLeaders.data]
            let setTmp = []

            tmp.sort((a, b) => b.index - a.index)

            tmp.forEach((e, i) => {
                if (i > 4) {
                    return
                } else {
                    setTmp.push({
                        index: (e.index).toFixed(2),
                        name: e.name
                    })
                }
            });

            setList(setTmp)
        }
    }, [dumpingLeaders])

    if (list.length === 0) return null

    return (
        <div className="compare-monitor">
            <h3>Лидеры демпинга</h3>
                <div>
                    {list.map((item, i) => 
                    <div key={i} className="d-leader">
                    <div style={{display: "flex"}}>
                        <span style={{display: 'inline-block', marginRight: '10px'}}>{i + 1}.</span>
                        <span style={{whiteSpace: "nowrap", overflow: "hidden", width: 160}}>{item.name}</span>
                    </div>
                    <div className="d-leader-index">
                        <span style={{display: 'inline-block', marginRight: '6px'}}>{item.index}</span>
                        <span>
{/*                             <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.3252 8.62414L11.2543 0.89834L3.5285 0.827462L5.97205 3.27102L0.443547 8.79952L3.35215 11.7081L8.88066 6.17963L11.3252 8.62414Z" fill="#DC2058"/>
                            </svg> */}
                        </span>
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}

export default Dleader
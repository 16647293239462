import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { getRadarDataMonitor } from '../../../../store/AppStore/monitorData';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
      r: {
        min: 0,
        beginAtZero: true,
         ticks: {
            stepSize: 1
         }
      }
    },
    
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
    },
};

const RadarChart = ({type}) => {

    const radarData = useSelector((state) => state.monitorData.radarData)

    const [d, setD] = useState(null)

    useEffect(() => {
        if (radarData) {
            let ls = []
            let vs = []

            radarData[type].forEach(element => {
                ls.push(element.label)

                if (element.value) {
                    vs.push(element.value)
                } else {
                    vs.push(0)
                }
            });

            let radar = {
                labels: ls,
                datasets: [
                  {
                    label: `${type === "count" ? "Количество" : "Сумма"}`,
                    data: vs,
                    backgroundColor: type === "count" ? 'rgba(220, 32, 88, 0.5)' : "rgba(57, 115, 103, 0.5)",
                    borderColor:  type === "count" ? 'rgb(220, 32, 88)' : "rgb(57, 115, 103)",
                    borderWidth: 1,
                  },
                ],
              };

              setD(radar)
        }
    }, [radarData, type])

    if (!d) {
        return null
    }

    return <Radar data={d} options={options} />
}

export default RadarChart

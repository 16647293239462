import RadarChart from "./radar"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    BarElement,
    Legend,
} from "chart.js";
import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
    },
  };

const SalesBlock = ({type}) => {

    const salesData = useSelector((state) => state.monitorData.salesData)

    const [data, setData] = useState(null)

    useEffect(() => {

        if (salesData) {
            if (type === "count") {
                setData( {
                    labels: salesData.labels,
                    datasets: [
                        {
                            label: "Количество",
                            backgroundColor: "rgb(220, 32, 88)",
                            borderColor: "rgb(255, 99, 132)",
                            data: salesData.count,
                        },
                    ],
                })
            } else {
                setData( {
                    labels: salesData.labels,
                    datasets: [
                        {
                            label: "Сумма",
                            backgroundColor: "rgb(57, 115, 103)",
                            borderColor: "rgb(255, 99, 132)",
                            data: salesData.sum,
                        },
                    ],
                })
            }
        }

    }, [salesData, type])

    if (!data) return null

    return (
        <div className="monitor-block">
            <div style={{flex: '0 0 280px'}}>
                <RadarChart type={type} />
            </div>
            <div style={{flexGrow: 1}}>
                <h3 style={{marginTop: 0, marginBottom: 10}}>Динамика продаж, {type === "count" ? "шт." : "руб."}</h3>
                <div style={{height: 230}}>
                    <Bar data={data} options={options}  />
                </div>
            </div>
        </div>
    )
}

export default SalesBlock
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCount, getSalesCount } from "../../../store/AppStore/appData"
import { useNavigate } from "react-router-dom"

const AppPage = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCount("products"))
        dispatch(getCount("sellers"))
        dispatch(getCount("dumping"))
        dispatch(getSalesCount(null))
    }, [dispatch])

    return (
        <div className="flex" style={{flexWrap: 'wrap', margin: -10}}>
            <ProductsBlock />
            <SellersBlock />
            <DumpingBlock />
            <SalesBlock />
        </div>
    )
}

export default AppPage

const ProductsBlock = () => {

    const navigate = useNavigate()
    const productsCount = useSelector((state) => state.appData.productsCount)

    return (
        <div className="settings-block linked"
            onClick={() => navigate("products")}>
            <h3>Продукция</h3>
            <div style={{fontSize: 120, fontWeight: 700, lineHeight: 1}}>{productsCount}</div>
        </div>
    )
}

const SellersBlock = () => {

    const navigate = useNavigate()
    const sellersCount = useSelector((state) => state.appData.sellersCount)

    return (
        <div className="settings-block linked"
            onClick={() => navigate("sellers")}>
            <h3>Продавцы</h3>
            <div style={{fontSize: 120, fontWeight: 700, lineHeight: 1}}>{sellersCount}</div>
        </div>
    )
}

const DumpingBlock = () => {

    const navigate = useNavigate()
    const dumpingCount = useSelector((state) => state.appData.dumpingCount)

    const [sum, setSum] = useState(0)

    useEffect(() => {
        let a, b
        a = dumpingCount.forCompanies?? 0
        b = dumpingCount.nullCompanies?? 0
        setSum(a + b)
    }, [dumpingCount])

    return (
        <div className="settings-block linked"
            onClick={() => navigate("dumping")}>
            <h3>Демпинг</h3>
            <div style={{fontSize: 120, fontWeight: 700, lineHeight: 1}}>{sum}</div>
        </div>
    )
}

const SalesBlock = () => {

    const navigate = useNavigate()
    const salesCount = useSelector((state) => state.appData.salesCount)

    return (
        <div className="settings-block linked"
            onClick={() => navigate("sales")}>
            <h3>Продажи c {salesCount.from}</h3>
            <div style={{fontSize: 120, fontWeight: 700, lineHeight: 1}}>{salesCount.count}</div>
        </div>
    )
}
import { useSelector } from "react-redux"
import Marquee from 'react-double-marquee';
import { useEffect, useState } from "react";

const Stringer = ({time}) => {
    return (
        <div className="monitor-string-main">
            <Clock time={time} />
            <String />
        </div>
    )
}

export default Stringer

const converter = (num) => {
    if (num < 10) {
        return '0' + num
    } else {
        return num
    }
}

const convertDate = (date) => {
    let dt = new Date(date)
    return converter(dt.getDate())+ '.' + converter(dt.getMonth() + 1) + " " + converter(dt.getHours()) + ":" + converter(dt.getMinutes())
}

const Clock = () => {
    const time = useSelector((state) => state.monitorData.monitorTime)

    const [d, setD] = useState(new Date())

    useEffect(() => {
        setD(new Date())
    }, [time])

    if (!time) {
        return null
    }

    return (
        <div className="monitor-clock">
            {converter(d.getHours())}:{converter(d.getMinutes())}
        </div>
    )
}

const String = () => {

    const stringData = useSelector((state) => state.monitorData.stringData)

    if (!stringData) return null

    return (
        <div className="monitor-string">
            <Marquee direction={"left"}>
                {stringData.data.map((e, i) => 
                            <span key={i} className="string-item">
                                <span>{convertDate(e.datetime)}</span> <span style={{fontWeight: 600}}>{e.seller}</span> {e.product} - {e.sum.toLocaleString('ru-RU')}₽
                            </span>
                )}
            </Marquee>
        </div>
    )
}
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useState } from "react";
import Preloader from "../../Preloader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const data = {
    "max": 2082418.5,
    "min": 100360.5,
    "labels": [
            "04.03.2024",
            "11.03.2024",
            "18.03.2024",
            "25.03.2024",
            "01.04.2024",
            "08.04.2024",
            "15.04.2024",
            "22.04.2024",
            "29.04.2024",
            "06.05.2024",
            "13.05.2024",
            "20.05.2024",
            "27.05.2024",
            "03.06.2024",
            "10.06.2024",
            "17.06.2024",
            "24.06.2024",
            "01.07.2024",
            "08.07.2024",
            "15.07.2024",
            "22.07.2024",
            "29.07.2024",
            "05.08.2024",
            "12.08.2024",
            "19.08.2024",
            "26.08.2024",
            "02.09.2024",
            "09.09.2024",
            "16.09.2024",
            "23.09.2024"
        ],
        "points": [
            0,
            0,
            0,
            0,
            104870.69,
            204381.1,
            253880.1,
            315425.8,
            357024.38,
            378422.2,
            264657.12,
            116905.17,
            214926.16,
            539873.7,
            255945.75,
            350516.12,
            369323.7,
            392915.3,
            189476.1,
            383766.2,
            350790,
            450533.3,
            458242.12,
            462315.6,
            575979.8,
            553982.6,
            423263.8,
            373690.62,
            315907.5,
            26546.99
        ],
        "middle": [
            0,
            0,
            0,
            0,
            20974.137,
            51541.965,
            80447.414,
            109819.71,
            137286.89,
            161400.42,
            170787.39,
            166297.2,
            170037.9,
            196454.75,
            200420.81,
            209801.78,
            219185.42,
            228837.08,
            226765.45,
            234615.47,
            240147.6,
            249710.6,
            258777.17,
            267257.94,
            279606.8,
            290159.72,
            295089.53,
            297896.7,
            298517.75,
            289452.06
        ],
}

const SalesGraph = () => {

    const [lineData, setLineData] = useState(null)

    useEffect(() => {
        if (data) {
            let dataLine = {
                labels : data.labels,
                datasets: [
                    {
                        data: data.points,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        borderColor: "rgb(190, 215, 48)",
                    },
                    {
                        data: data.middle,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        borderColor: "rgb(50, 215, 48)",
                        borderDash: [3, 3],
                    }
                ]
            }

            setLineData({data: dataLine})
        }
    }, [])

    if (!lineData) {
        return <Preloader />
    }

    return (
        <>
            <div style={{height: 400}}>
                <Line style={{width: '100%'}} data={lineData.data} options={lineData.options} />
            </div>
        </>
    )
}

export default SalesGraph
import TopBlock from "../../../../components/TopBlock"

const ProductsAppPage = () => {
    return (
        <>
            <TopBlock title="Продукция" back="Главная" backLink={"/app"} />
        </>
    )
}

export default ProductsAppPage
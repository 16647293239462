import { useSelector } from "react-redux"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    BarElement,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
);

const Monthes = () => {

    const monthData = useSelector((state) => state.monitorData.monthData)

    const [d, setD] = useState(null)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: false,
              position: 'top',
            },
          },
    }

    useEffect(() => {
        if (monthData) {
            let v1, v2, l1, l2
            monthData.data.forEach(element => {
                if (element.sort === 1) {
                    v1 = element.sum
                    l1 = element.sum.toLocaleString('ru-RU') + "₽"
                } else {
                    v2= element.sum
                    l2 = element.sum.toLocaleString('ru-RU') + "₽"
                }
            });

            setD({
                labels: [l1, l2],
                datasets: [
                    {
                        label: "Продаж на сумму",
                        backgroundColor: ["rgba(242, 187, 23, 0.5)", "rgba(20, 139, 41, 0.5)"],
                        borderColor: ["rgba(242, 187, 23, 1)", "rgba(20, 139, 41, 1)"],
                        data: [v1, v2],
                    },
                ],
            })

        }
    }, [monthData])

    if (!d) return null

    return (
        <div className="compare-monitor">
            <h3>Прошлый и текущий месяц на {monthData.date}</h3>
            <div style={{height: 370}}>
                <Bar data={d} options={options}  />
            </div>
            
        </div>
    )
}

export default Monthes
import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const appData = createSlice({
    name: 'appData',
    initialState: {
        productsCount: 0,
        sellersCount: 0,
        dumpingCount: {forCompanies:0, nullCompanies:0},
        salesCount: {count: 0, from: "2024-05-01"}
    },
    reducers: {
        setProductCount: (state, action) => {
            state.productsCount = action.payload
        },
        setSellerCount: (state, action) => {
            state.sellersCount = action.payload
        },
        setDumpingCount: (state, action) => {
            state.dumpingCount = action.payload
        },
        setSalesCount: (state, action) => {
            state.salesCount = action.payload
        }
    }
})

export const { setProductCount, setSellerCount, setDumpingCount, setSalesCount } = appData.actions

export const getCount = (type) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/${type}/count`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                switch (type) {
                    case 'products':
                        dispatch(setProductCount(response.data.result.count))
                        break
                    case 'sellers':
                        dispatch(setSellerCount(response.data.result.count))
                        break
                    case 'dumping':
                        dispatch(setDumpingCount(response.data.result))
                        break
                    default:
                        console.log(response.data.result)
                        break
                }
            }
        })
        .catch((error) => {
            console.log(error);
            switch (type) {
                case 'products':
                    dispatch(setProductCount(0))
                    break
                case 'sellers':
                    dispatch(setSellerCount(0))
                    break
                case 'dumping':
                    dispatch(setDumpingCount({forCompanies:0, nullCompanies:0}))
                    break
                default:
                    console.log(error)
                    break
            }
        });
}

export const getSalesCount = (from) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/sales/count?from=${from}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setSalesCount(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setSalesCount({count: 0, from: "2024-05-01"}))
        });
}


export default appData.reducer